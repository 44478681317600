<template>
  <upsell-page title="לקבל את ההחלטות הכלכליות ביחד"
               headline="הוספת שותף או שותפה למינוי"
               cta-text="הזמנה דרך וואטסאפ"
               :img-src="require('@/assets/invite-member.png')"
               :pageBackgroundColor="backgroundColor"
               :text-color="textColor"
               @click="inviteMember">
    <div>
      <div class="padding-bottom-m">
        מי שיצטרף אליך יראה את המידע בתזרים, יקבל את כל ההודעות בדיוק כמוך, ללא תוספת תשלום.
      </div>
      <div>
        הכנו לך הודעת <span class="preview ri-large-body" v-on:click="showPreview"> הזמנה אישית</span>, כל מה שצריך הוא לשלוח אותה בוואטסאפ.
      </div>
    </div>
  </upsell-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import router from '@/router';
import Segment from '@/Segment';
import config from '../../../config';
import InviteMemberHelpPopup from '../../../base-components/onboarding/help-popups/InviteMemberHelpPopup.vue';

export default {
  name: 'InviteMember',
  components: {
    UpsellPage: BaseUI.Pages.UpsellPage,
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupLightGreen,
      textColor: BaseUI.Colors.riseupDarkGreen,
    };
  },
  async created() {
    if (!this.inviteToken) {
      await this.initInviteToken();
    }
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.LIGHT_GREEN,
      textColor: BaseUI.Colors.riseupDarkGreen,
      logoColor: BaseUI.Colors.riseupDarkGreen,
      sticky: false,
    });
  },
  computed: {
    ...mapState('session', ['inviteToken']),
    ...mapGetters('session', ['inviteMessage']),
    inviteMemberLink() {
      return `${config.get().elsaPublicUrl}/invite?inviteToken=${this.inviteToken}`;
    },
    inviteMemberMessage() {
      // eslint-disable-next-line max-len
      return encodeURIComponent(`${this.inviteMessage}${this.inviteMemberLink}`);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('session', ['initInviteToken']),
    ...mapActions('appHeader', ['setAppHeaderProps']),
    onBack() {
      if (router.canGoBack(this.$route)) {
        router.go(-1);
      } else {
        router.push({ path: '/sr', query: this.$route.query });
      }
    },
    showPreview() {
      Segment.trackUserInteraction('InviteMemberPreviewClicked');
      this.openModal({
        component: InviteMemberHelpPopup,
      });
    },
    inviteMember() {
      Segment.trackUserInteraction('InviteMemberViaWhatsAppClicked');
      window.open(`https://api.whatsapp.com/send?text=${this.inviteMemberMessage}`, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.preview {
  color: $riseup_blue;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}
</style>
