<template>
  <loading-popup title="עוד רגע נמשיך..." />
</template>

<script>

import LoadingPopup from '@/base-components/onboarding/LoadingPopup';
import { mapActions } from 'vuex';
import CustomersApi from '../../../../api/CustomersApi';

export default {
  name: 'Signup2faDone',
  components: { LoadingPopup },
  async created() {
    await CustomersApi.assignAuth0Id();
    window.location.href = `/${this.$route.query.redirectTo || 'sr'}`;
  },
  methods: {
    ...mapActions('featureFlags', ['isFeatureFlagOn']),
  },
};
</script>
